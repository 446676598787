/**
* Generated theme by Material Theme Generator
* https://materialtheme.arcsine.dev
*/

@import "./calendar-styles.scss";

@import "~@angular/material/theming";
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.

// Fonts
@import "https://fonts.googleapis.com/css?family=Material+Icons";
@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700");
@import url("https://fonts.googleapis.com/css?family=Lato:300,400,500");

$fontConfig: (
  display-4: mat-typography-level(112px, 112px, 300, "Montserrat", -0.0134em),
  display-3: mat-typography-level(56px, 56px, 400, "Montserrat", -0.0089em),
  display-2: mat-typography-level(45px, 48px, 400, "Montserrat", 0em),
  display-1: mat-typography-level(34px, 40px, 400, "Lato", 0.0074em),
  headline: mat-typography-level(32px, 39px, 700, "Montserrat", 0.01em),
  //h1
  title: mat-typography-level(20px, 32px, 500, "Lato", 0.0075em),
  subheading-2: mat-typography-level(16px, 28px, 400, "Lato", 0.0094em),
  subheading-1: mat-typography-level(15px, 24px, 500, "Lato", 0.0067em),
  body-2: mat-typography-level(14px, 24px, 500, "Lato", 0.0179em),
  body-1: mat-typography-level(14px, 20px, 400, "Lato", 0.0179em),
  button: mat-typography-level(16px, 20px, 500, "Montserrat", 0.01em),
  caption: mat-typography-level(12px, 20px, 400, "Lato", 0.0333em),
  input: mat-typography-level(inherit, 1.125, 500, "Lato", 0.01em),
);

// h1 {
//   font-family: Montserrat;
//   font-style: normal;
//   font-weight: bold;
//   font-size: 32px;
//   line-height: 39px;
//   letter-spacing: 0.01em;
// }

// Foreground Elements

// Light Theme Text
$dark-text: #1a151f;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

$mat-light-theme-foreground: (
  base: black,
  divider: $dark-dividers,
  dividers: $dark-dividers,
  disabled: $dark-disabled-text,
  disabled-button: rgba($dark-text, 0.26),
  disabled-text: $dark-disabled-text,
  elevation: black,
  secondary-text: $dark-accent-text,
  hint-text: $dark-disabled-text,
  accent-text: $dark-accent-text,
  icon: $dark-accent-text,
  icons: $dark-accent-text,
  text: $dark-primary-text,
  slider-min: $dark-primary-text,
  slider-off: rgba($dark-text, 0.26),
  slider-off-active: $dark-disabled-text,
);

// Dark Theme text
$light-text: #f4eff9;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

$mat-dark-theme-foreground: (
  base: $light-text,
  divider: $light-dividers,
  dividers: $light-dividers,
  disabled: $light-disabled-text,
  disabled-button: rgba($light-text, 0.3),
  disabled-text: $light-disabled-text,
  elevation: black,
  hint-text: $light-disabled-text,
  secondary-text: $light-accent-text,
  accent-text: $light-accent-text,
  icon: $light-text,
  icons: $light-text,
  text: $light-text,
  slider-min: $light-text,
  slider-off: rgba($light-text, 0.3),
  slider-off-active: rgba($light-text, 0.3),
);

// Background config
// Light bg
$light-background: #f4eff9;
$light-bg-darker-5: darken($light-background, 5%);
$light-bg-darker-10: darken($light-background, 10%);
$light-bg-darker-20: darken($light-background, 20%);
$light-bg-darker-30: darken($light-background, 30%);
$light-bg-lighter-5: lighten($light-background, 5%);
$dark-bg-tooltip: lighten(#1a151f, 20%);
$dark-bg-alpha-4: rgba(#1a151f, 0.04);
$dark-bg-alpha-12: rgba(#1a151f, 0.12);

$mat-light-theme-background: (
  background: $light-background,
  status-bar: $light-bg-darker-20,
  app-bar: $light-bg-darker-5,
  hover: $dark-bg-alpha-4,
  card: $light-bg-lighter-5,
  dialog: $light-bg-lighter-5,
  tooltip: $dark-bg-tooltip,
  disabled-button: $dark-bg-alpha-12,
  raised-button: $light-bg-lighter-5,
  focused-button: $dark-focused,
  selected-button: $light-bg-darker-20,
  selected-disabled-button: $light-bg-darker-30,
  disabled-button-toggle: $light-bg-darker-10,
  unselected-chip: $light-bg-darker-10,
  disabled-list-option: $light-bg-darker-10,
);

// Dark bg
$dark-background: #1a151f;
$dark-bg-lighter-5: lighten($dark-background, 5%);
$dark-bg-lighter-10: lighten($dark-background, 10%);
$dark-bg-lighter-20: lighten($dark-background, 20%);
$dark-bg-lighter-30: lighten($dark-background, 30%);
$light-bg-alpha-4: rgba(#f4eff9, 0.04);
$light-bg-alpha-12: rgba(#f4eff9, 0.12);

// Background palette for dark themes.
$mat-dark-theme-background: (
  background: $dark-background,
  status-bar: $dark-bg-lighter-20,
  app-bar: $dark-bg-lighter-5,
  hover: $light-bg-alpha-4,
  card: $dark-bg-lighter-5,
  dialog: $dark-bg-lighter-5,
  tooltip: $dark-bg-lighter-20,
  disabled-button: $light-bg-alpha-12,
  raised-button: $dark-bg-lighter-5,
  focused-button: $light-focused,
  selected-button: $dark-bg-lighter-20,
  selected-disabled-button: $dark-bg-lighter-30,
  disabled-button-toggle: $dark-bg-lighter-10,
  unselected-chip: $dark-bg-lighter-20,
  disabled-list-option: $dark-bg-lighter-10,
);

// Compute font config
@include mat-core($fontConfig);

// Theme Config

body {
  margin: 0;
  background-color: #f3f4f5;
  --primary-color: #832ef1;
  --primary-lighter-color: #dac0fb;
  --primary-darker-color: #661deb;
  --text-primary-color: #{$light-primary-text};
  --text-primary-lighter-color: #{$dark-primary-text};
  --text-primary-darker-color: #{$light-primary-text};
}

$mat-primary: (
  main: #832ef1,
  lighter: #dac0fb,
  darker: #661deb,
  200: #832ef1,
  // For slide toggle,
  contrast:
    (
      main: $light-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);
$theme-primary: mat-palette($mat-primary, main, lighter, darker);

body {
  --accent-color: #ef39a0;
  --accent-lighter-color: #fac4e3;
  --accent-darker-color: #e82484;
  --text-accent-color: #{$light-primary-text};
  --text-accent-lighter-color: #{$dark-primary-text};
  --text-accent-darker-color: #{$light-primary-text};
}

$mat-accent: (
  main: #ef39a0,
  lighter: #fac4e3,
  darker: #e82484,
  200: #ef39a0,
  // For slide toggle,
  contrast:
    (
      main: $light-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);
$theme-accent: mat-palette($mat-accent, main, lighter, darker);

body {
  --warn-color: #ff0000;
  --warn-lighter-color: #ffb3b3;
  --warn-darker-color: #ff0000;
  --text-warn-color: #{$light-primary-text};
  --text-warn-lighter-color: #{$dark-primary-text};
  --text-warn-darker-color: #{$light-primary-text};
}

$mat-warn: (
  main: #ff0000,
  lighter: #ffb3b3,
  darker: #ff0000,
  200: #ff0000,
  // For slide toggle,
  contrast:
    (
      main: $light-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);
$theme-warn: mat-palette($mat-warn, main, lighter, darker);

$theme: mat-light-theme($theme-primary, $theme-accent, $theme-warn);
$altTheme: mat-dark-theme($theme-primary, $theme-accent, $theme-warn);

// Theme Init
@include angular-material-theme($theme);

.theme-alternate {
  @include angular-material-theme($altTheme);
}

// Specific component overrides, pieces that are not in line with the general theming

// Handle buttons appropriately, with respect to line-height
.mat-raised-button,
.mat-stroked-button,
.mat-flat-button {
  padding: 0 1.15em;
  margin: 0 0.65em;
  min-width: 3em;
  line-height: 36.4px;
}

.mat-standard-chip {
  padding: 0.5em 0.85em;
  min-height: 2.5em;
}

// [MINI-CALENDAR Component] To change the color of the selected date
.mat-calendar-body-selected {
  background-color: #dac0fb;
  color: black;
}

// [MINI-CALENDAR Component] Change the display of cells on mini-calendar
.mat-calendar-body-cell-content {
  border-width: 0px !important;
  border-style: none !important;
}

// [MY-CALENDARS Component] Change color of checkbox outline
// .mat-checkbox-frame {
//   border-color: #832ef1;
// }

// Change All Angular Material Dialog Container Style
.mat-dialog-container {
  border-radius: 10px !important;
}

.material-icons {
  font-size: 24px;
  font-family: "Material Icons", "Material Icons";
  .mat-badge-content {
    font-family: "Lato";
  }
}

a {
  color: #4c8bf5;
  font-weight: bold;
}

.asuvi-accent {
  color: #832ef1;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: spinner 0.8s linear infinite;
}

select {
  // A reset of styles, including removing the default dropdown arrow
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0 1em 0 0;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;

  // Stack above custom arrow
  z-index: 1;

  // Remove dropdown arrow in IE10 & IE11
  // @link https://www.filamentgroup.com/lab/select-css.html
  &::-ms-expand {
    display: none;
  }

  // Remove focus outline, will add on alternate element
  outline: none;
}

.mat-expansion-panel {
  border-radius: 10px !important;
  box-shadow: 0px 4px 10px rgba(92, 87, 97, 0.09) !important;
}

.mat-expansion-indicator {
  display: flex;
}

.mat-calendar-body-label {
  padding: 0 !important;
  color: white;
}

.mat-calendar-header {
  padding: 1px 8px 0 8px !important;
}

.mat-calendar-table-header-divider {
  padding: 0 !important;
}

.mat-expansion-panel-body {
  padding: 0 !important;
}
