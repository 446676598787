// Inner Container for the date (31, Sunday)
// To display in vertical mode
.fc .fc-col-header-cell-cushion {
  display: flex !important;
  flex-direction: column;
  align-items: center;
}

// The Times on the side: 11 AM, 12 PM, etc
.fc-timegrid-label {
  width: 80px;
}

// Control the size of the cells on FullCalendar
.fc .fc-timegrid-slot {
  height: 2em !important;
}

// The div container for the Dates (31, Sunday)
.fc-col-header-cell {
  border: 0 !important;
  border-bottom: 1px solid #c1bcc6 !important;
}
.fc .fc-timegrid-slot-minor {
  border-top-style: none !important;
}
.fc-timegrid-axis {
  border: 0 !important;
  background: #f3f4f5;
}
// =========================================== //

// The Date Title in the header toolbar
// Ex: Jan 31 - Feb 6, 2021
.fc .fc-toolbar-title {
  font-family: Montserrat !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 22px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  margin-left: 140px !important;
}

// Top Left Corner of Calendar (Sunday)
// .fc-day-sun  {
// background: #f3f4f5;
//   .fc-col-header-cell-cushion  {
//       border-radius: 10px 0px 0px 0px !important;
//       background: #ffffff;
//   }
// }

// Top Right Corner of Calendar (Saturday)
// .fc-day-sat {
// background: #f3f4f5;
// .fc-col-header-cell-cushion {
//   border-radius: 0px 10px 0px 0px !important;
//   background: #ffffff;
// }
// }

// ===== 100% with for ENTIRE calendar: title + grid ===== //
.fc-col-header {
  width: 100% !important;
}
.fc-daygrid-body {
  width: 100% !important;
}
.fc-scrollgrid-sync-table {
  width: 100% !important;
}
.fc-timegrid-body {
  width: 100% !important;
  table {
    width: 100% !important;
  }
}
.fc-timegrid-cols {
  width: 100% !important;
}
// ====================================================== //

// Hides the scroller for the Calendar Header
.fc-scroller {
  overflow: hidden !important;
}

// Each Chunk in the Header Toolbar:
// Start (left-side), Center, End (right-side)
.fc-toolbar-chunk {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
}

// ===== Hide the scrollbar for the calendar body ===== //
.fc-scroller-liquid-absolute {
  overflow-y: scroll !important;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.fc-scroller-liquid-absolute::-webkit-scrollbar {
  display: none;
  width: 100% !important;
}
// =================================================== //

// Get rid of the all-day row
.fc-timegrid-divider {
  display: none !important;
}

// Remove the 30 minute dotted border lines
.fc-theme-standard .fc-scrollgrid {
  border: 0 !important;
}

// Time slot labels Position 1PM , 2PM etc.
.fc .fc-timegrid-slot-label-cushion {
  top: -12px;
  position: relative;
}

// Time slot labels typography
.fc-timegrid-slot-label {
  border: 0 !important;
  font-family: Montserrat;
  font-size: 9px;
  font-style: normal;
  font-weight: 500;
  line-height: 11px;
  letter-spacing: 0em;
  text-align: left;
  text-transform: uppercase;
}

// The container that displays "all-day"
// .fc-timegrid-axis {
//padding: 20px 0 !important;
// }

// Header for FullCalendar Container
.fc .fc-toolbar.fc-header-toolbar {
  background-color: #f3f4f5;
  margin-bottom: 0 !important;
}

// The Number on the calendar like: 23, 24
.calendar-day-header-date {
  height: 46px;
  width: 46px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 46px;
  letter-spacing: 0.01em;
  color: #5c5761;
}

// Purple Circle around the active number
.calendar-day-header-date-active {
  height: 46px;
  width: 46px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 46px;
  letter-spacing: 0.01em;
  color: #5c5761;
  background: rgba(131, 46, 241, 0.25);
  border-radius: 50%;
}

// The Days on the Calendar like: Monday, Tuesday
.calendar-day-header-day {
  margin-top: 6px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #c1bcc6;
  text-transform: uppercase;
  padding-bottom: 5px;
}
